<template>
  <div>
    <form novalidate action="" role="form" id="myForm">

      <section class="kjn-hero mb-4">
        <div class="container">
          <div class="d-lg-flex d-md-flex d-xxl-flex" style="gap: 15px">
            <h2>Klasifikasi Baku Jabatan Indonesia (KBJI) tahun 2014</h2>
            <div>
              <a @click.prevent="downloadKBJI" class="btn btn-ternary text-white" :class="{ 'btn-ternary': !unduhDisable , 'btn-secondary': unduhDisable, 'disabled': unduhDisable }">Unduh</a>
            </div>
          </div>
          <div class="row mt-4">
            <div class="search-bar big">
              <div class="input-group position-search left-bigger align-items-center">
                <input type="text" :placeholder="placeholder" class="form-control"
                  name="keyword" formcontrolname="keyword" id="keyword" v-model="searchVal" />
                <div class="btn-wrapper input-group-append input-group-lg">
                  <button @click.prevent="searchByKeyword" class="btn btn-ternary text-white" name="cari">Cari</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>

    <section class="container">
      <div class="mt-3">
        <nav aria-label="breadcrumb" style="--bs-breadcrumb-divider: '>'">
          <ol class="breadcrumb breadcrumb-container">
            <li
              :class="breadcrumbClass(idx)"
              aria-current="page"
              v-for="(step, idx) in steps"
              :key="idx"
            >
              <strong>
                <a @click="toBreadcrumb(step.val, idx)" style="cursor: pointer;">{{ step.label }}</a>
              </strong>
            </li>
          </ol>
        </nav>
          Menampilkan <strong> {{ total }} {{ getHeaderLabel() }} </strong>
      </div>

      <!-- table -->
      <div class="card shadow-sm border-0 mb-3">
        <div class="card-body" style="overflow: auto">
          <table class="table table-search" style="width:100%">

            <thead>
              <tr>
                <th
                  class="border-bottom-0 fit"
                >
                  <h4 class="fw-semibold">{{ tableHeaderShow[0].label }}</h4>
                </th>
                <th
                  class="border-bottom-0"
                >
                  <h4 class="fw-semibold">{{ tableHeaderShow[1].label }}</h4>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr class="align-items-center tbody-tr"
                v-for="(tData, idx) in data"
                :key="idx"
              >
                <td class="text-center">
                  <h5>{{ tData[tableHeaderShow[0].prop] }}</h5>
                </td>
                <td class="text-left">
                  <div class="d-flex flex-column">
                    <a @click="toData(tData)" class="text-primary text-decoration-none fw-medium" style="cursor: pointer;">{{ tData[tableHeaderShow[1].prop] }}</a>
                    <a v-show="!isJabatan(tData)" @click="toDetail(tData[tableHeaderShow[0].prop])" class="text-lighter-custom text-primary" style="cursor: pointer;">Detail</a>
                  </div>
                  <!-- <a @click="toData(tData)" :class="getTableDataClass()">
                    <h5 </h5>
                  </a> -->
                </td>
              </tr>
              <tr v-if="(data.length < 1 || data === '') && !isLoading">
                <td colspan="2" class="text-center">Tidak Ada Data</td>
              </tr>

              <tr v-show="isLoading">
                <td colspan="4">
                  <div class="d-flex justify-content-center" style="min-width: 100%">
                    <div class="spinner-border"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- <a href="@/assets/documents/kbji.pdf" download class="d-none" ref="downloadKBJI"></a> -->
    <a href="/files/kbji.pdf" download class="d-none" ref="downloadKBJI"></a>


  </div>
</template>

<script>
import api from './api'
import swal from 'sweetalert2'
import auth from '../../helpers/auth.js'

export default {

  data () {
    return {
      isLoading: false,
      data: [],
      tableHeaderShow: [],
      tableHeaderSearch: [
        {
          label: 'Kode',
          prop: 'kode'
        },
        {
          label: 'Jabatan',
          prop: 'golongan'
        },
      ],
      tableHeader: [
        [
          {
            label: 'Kode Golongan Pokok',
            prop: 'kode'
          },
          {
            label: 'Golongan Pokok',
            prop: 'golongan'
          },
        ],
        [
          {
            label: 'Kode Subgolongan Pokok',
            prop: 'kode'
          },
          {
            label: 'Subgolongan Pokok',
            prop: 'golongan'
          }
        ],
        [
          {
            label: 'Kode Golongan',
            prop: 'kode'
          },
          {
            label: 'Golongan',
            prop: 'golongan'
          }
        ],
        [
          {
            label: 'Kode Subgolongan',
            prop: 'kode'
          },
          {
            label: 'Subgolongan',
            prop: 'golongan'
          }
        ],
        [
          {
            label: 'Kode Jabatan',
            prop: 'kode'
          },
          {
            label: 'Jabatan',
            prop: 'golongan'
          }
        ],
      ],
      steps: [
        {
          label: 'KBJI 2014',
          val: 'kbji'
        },
      ],
      kode: 1,
      level: 1,
      limit: 10,
      currPage: 1,
      showDetail: true,
      searchVal: null,
      nextPage: null,
      lastPage: null,
      total: 0,
      loadScroll: false,
      placeholder: 'Masukan Keyword',
      unduhDisable: true
    }
  },
  created () {
    this.getKbji()
    this.searchVal = this.$route.query.keyword
    this.getUserData()
    window.addEventListener('scroll', this.handleScrollKbji)
    // console.log('add event listener')
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScrollKbji)
  },
  watch: {
    $route (to, from) {
      // location.reload()
      this.getKbji()
    }
  },
  methods: {
    toKBJI () {
      if (this.$route.path !== '/home/kjn_golongan') {
        this.searchVal = null
        this.$router.push('/home/kjn_golongan')
      }
    },
    toDetail (kode) {
      const { level } = this.$route.query
      let currLevel = 0
      console.log('level', level)
      if (level !== 'undefined' && level !== null && level !== undefined) {
        currLevel = level
      } else {
        if (kode?.split('.').length > 1) {
          currLevel = 5
        } else {
          currLevel = kode.split('').length
        }
      }
      this.$router.push(`/home/kjn_golongan_detail?kode=${kode}&level=${currLevel}`)
    },
    async getKbji () {
      this.isLoading = true
      this.getHeader()
      // let notFirstFetch = true
      const { kode, level, keyword } = this.$route.query
      // console.log('query', kode, level, keyword)
      const currKode = kode ? kode : this.kode
      const currLevel = Number(level)
      
      try {
        let res = {}
        if (keyword && keyword !== undefined && keyword !== 'undefined' && keyword !== null && keyword !== '') {
          
          const params = {
            limit: this.limit,
            id: currKode,
            keyword: keyword
          }
          switch (currLevel) {
            case 2:
              res = await api.getGol2Search(params, this.nextPage)
              break;
            case 3:
              res = await api.getGol3Search(params, this.nextPage)
              break;
            case 4:
              res = await api.getGol4Search(params, this.nextPage)
              break;
            case 5:
              res = await api.getGol5Search(params, this.nextPage)
              break;
            default:
              const params2 = {
                limit: this.limit,
                id: currKode
              }
              res = await api.getSearchKBJIAll(keyword, params2, this.nextPage)
              break;
          }
        } else {

          const params = {
            limit: this.limit,
            id: currKode
          }

          switch (currLevel) {
            case 2:
              res = await api.getGol2(params, this.nextPage)
              break;
            case 3:
              res = await api.getGol3(params, this.nextPage)
              break;
            case 4:
              res = await api.getGol4(params, this.nextPage)
              break;
            case 5:
              res = await api.getGol5(params, this.nextPage)
              break;
            default:
              res = await api.getGol1(params, this.nextPage)
              break;
          }
        }
        console.log('res', res)
        if (!res.data.content) {
          if (res.data.next_page_url) {
            this.nextPage = res.data.next_page_url
          } else {
            this.nextPage = null
          }
          this.lastPage = res.data.last_page
          this.total = res.data.total
        } else {
          if (res.data.content.next_page_url) {
            this.nextPage = res.data.content.next_page_url
          } else {
            this.nextPage = null
          }
          this.lastPage = res.data.content.last_page
          this.total = res.data.content.total
        }
        if (res.data.links) {
          this.getSteps(res.data.links)
        } else {
          this.getSteps()
        }
        const dataContainer = !res.data.content ? res.data.data : res.data.content.data
        const data = dataContainer && dataContainer !== '' ? dataContainer : []

        if (this.loadScroll === true && (data !== '' && data.length > 0)) {
          this.data = [ ...JSON.parse(JSON.stringify(this.data)), ...JSON.parse(JSON.stringify(data)) ]
          this.loadScroll = false
        } else if (data !== '' && data.length > 0) {
          this.data = JSON.parse(JSON.stringify(data))
        } else {
          this.data = []
        }
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error when fetching data'
        })
      } finally {
        this.isLoading = false
      }
    },
    toData (data) {
      const { keyword, level } = this.$route.query
      this.nextPage = null
      if (this.isJabatan(data)) {
        if (keyword) {
          this.$router.push(`/home/kjn_golongan_detail?kode=${data[this.tableHeaderShow[0].prop]}&level=${data.level}`)
        } else {
          this.$router.push(`/home/kjn_golongan_detail?kode=${data[this.tableHeaderShow[0].prop]}&level=${this.level}`)
        }
        return ''
      }
      this.kode = data[this.tableHeaderShow[0].prop]
      // this.data = []
      let routerPath = ''
      if (keyword) {
        this.level = data.level + 1
        routerPath = `/home/kjn_golongan?kode=${this.kode}&level=${data.level + 1}`
      } else {
        this.level = level ? Number(level) + 1 : 2
        routerPath = `/home/kjn_golongan?kode=${this.kode}&level=${this.level}`
      }

      if (this.$route.fullPath !== routerPath) {
        this.$router.push(`/home/kjn_golongan?kode=${this.kode}&level=${this.level}`)
        return ''
      }
      this.getKbji()
    },
    async getSteps (links) {
      console.log('links', links)
      let newLinks = []
      let steps = []

      const newSteps = [
        {
          label: 'KBJI 2014',
          val: 'kbji',
          level: 0
        },
      ]
      if (links !== false && links !== undefined && links !== '') {
        newLinks = links.map(link => link[0])
        if (newLinks[0] !== undefined && newLinks !== null && newLinks[0] !== 'undefined') {
          steps = newLinks.map(step => {
            return {
              label: `${step.kode} ${step.golongan}`,
              val: step.kode,
              level: step.level
            }
          })
        }
      }
      this.level = newLinks.length > 0 && newLinks[0] !== undefined && newLinks !== null && newLinks[0] !== 'undefined' ? newLinks[newLinks.length - 1].level + 1 : this.level
      this.steps = JSON.parse(JSON.stringify([ ...newSteps, ...steps]))
    },
    getHeader () {
      const { level, keyword } = this.$route.query
      if (keyword) {
        this.tableHeaderShow = JSON.parse(JSON.stringify(this.tableHeaderSearch))
      } else if (!level) {
        this.tableHeaderShow = JSON.parse(JSON.stringify(this.tableHeader[0]))
      } else if (level == 2) {
        this.tableHeaderShow = JSON.parse(JSON.stringify(this.tableHeader[1]))
      } else if (level == 3) {
        this.tableHeaderShow = JSON.parse(JSON.stringify(this.tableHeader[2]))
      } else if (level == 4) {
        this.tableHeaderShow = JSON.parse(JSON.stringify(this.tableHeader[3]))
      } else if (level == 5) {
        this.tableHeaderShow = JSON.parse(JSON.stringify(this.tableHeader[4]))
      }
    },
    toBreadcrumb (kode, idx) {
      const kodeQ = this.$route.query.kode
      const levelQ = this.$route.query.level
      this.searchVal = null
      this.nextPage = null
      this.lastPage = null
      console.log(kodeQ, kode, levelQ, idx + 1)
      if (kode == 'kbji') {
        this.$router.push(`/home/kjn_golongan`)
        this.kode = 1
        this.level = 1
      } else if (kodeQ !== kode && levelQ !== idx + 1) {
        this.$router.push(`/home/kjn_golongan?kode=${kode}&level=${idx + 1}`)
      }
      // this.kode = 
      this.getKbji()
    },
    getUserData () {
      const user = auth.getUser()
      console.log('user', user)
      if (user && user !== null && user !== undefined && user !== '') {
        this.unduhDisable = false
      }
    },
    breadcrumbClass (idx) {
      let breadClass = 'custom-bread-color breadcrumb-item'
      if (idx == (this.steps.length - 1)) {
        breadClass += ' active'
      } else {
        breadClass += ' text-lighter-custom'
      }
      return breadClass
    },
    getTableDataClass () {
      if (this.tableHeaderShow[1].prop === 'namaJabatan') {
        return 'text-ternary text-decoration-none fw-medium'
      } else {
        return 'text-primary text-decoration-none fw-medium'
      }
    },
    searchByKeyword () {
      const { kode, level } = this.$route.query
      let query = ''
      query += kode ? `kode=${kode}` : ''
      query += level ? `&level=${level}` : ''
      query += kode ? `&keyword=${this.searchVal}` : `keyword=${this.searchVal}`
      const routerPath = `/home/kjn_golongan?${query}`
      if (this.$route.fullPath !== routerPath) {
        this.$router.push(routerPath)
      }
    },
    isJabatan (data) {
      const kode = data.kode ? data.kode : data.jabatan_kode
      const splitKode = kode.split('.')
      if (splitKode.length > 1) {
        return true
      } else {
        return false
      }
    },
    async handleScrollKbji () {
      if (this.isLoading || this.currPage == this.lastPage) return ''

      const deviation = 5
      let scrollHeight = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight
      let bottomOfWindow = ((scrollHeight - deviation) <= document.documentElement.offsetHeight) && (document.documentElement.offsetHeight <= (scrollHeight + deviation))

      if (bottomOfWindow) {
        if (this.nextPage && this.nextPage !== '') {
          this.loadScroll = true
          await this.getKbji()
        }
      }
    },
    getHeaderLabel () {
      if (this.tableHeaderShow !== null && this.tableHeaderShow !== undefined || this.tableHeaderShow.length > 0) {
        if (this.tableHeaderShow[1].label) {
          return `${this.tableHeaderShow[1].label}`
        } else {
          return 'Jabatan'
        }
      }
    },
    async downloadKBJI () {
      if (this.unduhDisable) return ''
      const user = auth.getUser()
      if (user && user !== null && user !== undefined && user !== '') {
        try {
          const params = {
            email: user.email,
            ref: 1,
            token: auth.getSSOToken()
          }

          const res = await api.postDownloadLog(params, { Authorization: `Bearer ${auth.getToken()}` })
          console.log('res', res)
          this.$refs.downloadKBJI.click()
        } catch (err) {
          console.log(err)
          swal.fire({
            icon: 'error',
            title: 'Gagal Unduh KBJI'
          })
        }
      } else {
        window.location.href = `https://account.kemnaker.go.id/auth?response_type=code&client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_LOGIN_URL}&scope=basic email`
        // window.location.href = 'https://account.kemnaker.go.id/auth?response_type=code&client_id=01723b89-180d-436d-abd5-dcab7869547e&redirect_uri=https://dev.otellogroup.id/&scope=basic email'
        // window.location.href = 'https://account.kemnaker.go.id/auth?response_type=code&client_id=01723b89-180d-436d-abd5-dcab7869547e&redirect_uri=http://localhost:8080/&scope=basic email'
      }
    }
    // moveZero (data) {
    //   const dataLength = data.length
    //   let startZeroIndex = null
    //   let endZeroIndex = null
    //   for (let i = 0; i <= dataLength; i++) {
    //     if (data[i])
    //   }
    // }
  }
}
</script>

<style>
  @import url('../../assets/styles/kjn.css');

  .breadcrumb {
    background-color: #e9ecef;
    border-radius: .25rem;
  }

  .custom-bread-color a {
    color: #15406a !important;
  }
</style>